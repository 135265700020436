.product-tile-pd {
  font-family: "Helvetica Neue"; }
  .product-tile-pd .product-tile-image {
    font-size: 1em; }
    .product-tile-pd .product-tile-image .quick-shop {
      background-color: #fff;
      color: #000;
      font-weight: 600;
      -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.4);
              box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.4);
      position: relative;
      border-radius: 0; }
    .product-tile-pd .product-tile-image .product-tile-component-figure {
      width: 100%; }
    .product-tile-pd .product-tile-image .product-tile-component-image {
      width: 100%; }
  .product-tile-pd .product-tile-image .quick-shop {
    display: none; }
  @media (min-width: 769px) {
    .product-tile-pd .product-tile-body {
      font-size: 1em;
      padding: 0.625em 1em; } }
  @media (max-width: 768.98px) {
    .product-tile-pd .product-tile-body {
      font-size: 0.75em;
      padding: 0.3125em 0.5em; } }
  .product-tile-pd .product-tile-body .product-name-link {
    color: #222; }
  .product-tile-pd .product-tile-body .product-price {
    font-weight: 700;
    color: #222; }
    .product-tile-pd .product-tile-body .product-price .strike-through {
      text-decoration: line-through;
      color: #999; }
    .product-tile-pd .product-tile-body .product-price .sales.reduced-price {
      color: #c00; }
  .product-tile-pd .product-tile-body .product-ratings .rating-number {
    font-size: 0.75em;
    font-weight: 400;
    color: #444; }
  @media (min-width: 769px) {
    .product-tile-pd .product-tile-body .color-swatches {
      padding-top: 0.3125em; } }
  @media (max-width: 768.98px) {
    .product-tile-pd .product-tile-body .color-swatches {
      padding-top: 0.1875em; } }
  .product-tile-pd .product-tile-body .color-swatches .product-tile-color-label {
    cursor: pointer;
    font-size: 1em; }
    @media (max-width: 991.98px) {
      .product-tile-pd .product-tile-body .color-swatches .product-tile-color-label {
        font-size: 0.9375em; } }
    @media (max-width: 768.98px) {
      .product-tile-pd .product-tile-body .color-swatches .product-tile-color-label {
        font-size: 0.8125em; } }
  .product-tile-pd .product-tile-body .color-swatches .swatches-ul {
    list-style: none;
    padding: 0; }
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li {
      background-repeat: no-repeat;
      background-position: 25%;
      margin-right: 0.75em;
      display: inline-block; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li .swatch-button-li {
        border-radius: 50%;
        width: 1.25em;
        height: 1.25em;
        border: 0.1875em solid white;
        padding: 0;
        -webkit-box-shadow: 0 0 0 0.125em #ccc;
                box-shadow: 0 0 0 0.125em #ccc;
        -webkit-box-sizing: content-box;
                box-sizing: content-box; }
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li:nth-child(1) .swatch-button-li {
      -webkit-box-shadow: 0 0 0 0.125em #999;
              box-shadow: 0 0 0 0.125em #999; }
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li,
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li-sm {
      display: inline-block; }
    @media (max-width: 768.98px) {
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li:nth-child(4),
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li:nth-child(5) {
        display: none; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li {
        display: none; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li-sm {
        display: inline; } }
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches,
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-sm {
      font-size: 1em;
      font-weight: 300;
      color: #444;
      vertical-align: bottom; }
    @media (min-width: 769px) {
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li {
        display: inline; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li-sm {
        display: none; } }
